import React from 'react';
import { Box, Flex, Grid, GridItem, Image, useBreakpointValue } from '@chakra-ui/react';
import UiUxInvoiceContent from '../components/UiUxInvoiceContent';
import UiUxInvoiceContentMobile from '../components/UiUxInvoiceContentMobile';

export type Theme = 'eg' | 'ams' | 'invoice' | 'ams2' | 'nature' | 'goodnite' | 'cheezy';

export interface contentThemesProps {
  title: string;
  date: string;
  support: string;
  description: string;
  link?: string;
  titleLink?: string;
}

const contentThemes: { [key in Theme]: contentThemesProps } = {
  eg: {
    title: 'ENTREGEEKS',
    date: '2023 - 2024',
    support: 'WEB',
    link: 'https://entre-geeks.com',
    titleLink: 'ENTRE-GEEKS',
    description:
      "EntreGeeks est une communauté en ligne dédiée aux passionnés de culture geek et de gaming. J'ai réalisé la refonte complète du site internet, incluant le branding et le design system, en créant un design moderne et intuitif. Le site propose une plateforme dynamique pour organiser des événements et des discussions, avec un serveur Discord actif pour des interactions en temps réel.",
  },
  ams: {
    title: 'PICKup-services AMS',
    date: '2023 - 2024',
    support: 'MOBILE',
    description:
      "Pickup-Services est une entreprise spécialisée dans la gestion des points relais en France et en Europe. Ils m'ont confié l’application mobile internationale de gestion de colis. J'ai créé de nouvelles pages et de nouveaux composants pour le design system, tout en respectant la direction artistique imposée.",
  },
  invoice: {
    title: 'PICKup-services Invoice NG',
    date: '2022 - 2023',
    support: 'application Web interne',
    description:
      "Pickup-Services est une entreprise spécialisée dans la gestion des points relais en france et en Europe. J'ai conçu le design system, ainsi que le illustration de l’application de facturation interne.",
  },
  ams2: {
    title: 'Musée de l’absinthe',
    date: '2022 - 2022',
    support: 'WEB',
    link: 'https://www.figma.com/proto/HHOIroyhAIOBECHuEKGNQu/www.musee-absinthe.com?page-id=0%3A1&node-id=1-2921&viewport=1049%2C457%2C0.12&t=dS1lu10xFaX8WvVB-1&scaling=min-zoom&content-scaling=fixed&starting-point-node-id=1%3A3186&show-proto-sidebar=1',
    titleLink: 'Prototype FIGMA Musee de l’absinthe',
    description:
      "Le Musée de l’Absinthe, situé à Auvers-sur-Oise, fait découvrir l’histoire de la « Fée verte ». Pour l’inauguration d’une nouvelle exposition temporaire, j'ai réalisé la refonte complète du site internet ainsi que le rebranding, en créant un design moderne et intuitif",
  },
  nature: {
    title: 'PICKup-services Invoice NG',
    date: '2023 - 2024',
    support: 'WEB',
    link: 'https://www.figma.com/proto/GFY1G6BcP6GkE7tEKfgkIF/Nature-Mort?page-id=15%3A355&node-id=99-1946&viewport=308%2C412%2C0.2&t=VRAGivEZpP6Ejl0q-1&scaling=scale-down&content-scaling=fixed',
    titleLink: 'Prototype FIGMA NATURE MORTE',
    description:
      'Une application mobile conçue pour une boutique vintage, offrant une interface élégante et intuitive. Elle permet de présenter les produits de la boutique tout en mettant en avant ses participations aux événements. Son identité visuelle rétro et son expérience utilisateur fluide assurent une navigation agréable et immersive.',
  },
  goodnite: {
    title: 'Goodnite',
    date: '2023 - 2024',
    support: 'WEB',
    link: 'https://www.figma.com/proto/b6wyXh4vTk1ANIKkOAm2U9/PorteFolio?node-id=1723-38240&t=bp42ZSMTQkWt5sat-1',
    titleLink: 'Prototype FIGMA du carrousel motion',
    description:
      'Goodnite, créé en 2019 par Valouzz, a été relancé par Robinouzz et Pyrox avec une nouvelle version du site et une stratégie marketing axée sur les concours et collaborations. Malgré une communauté affaiblie, le site a obtenu des contrats BtoB. La prochaine étape est le lancement d’une branche e-sport Fortnite avec compétitions et événements digitaux.',
  },
  cheezy: {
    title: 'Cheezy',
    date: '2023 - 2024',
    support: 'WEB',
    link: 'https://www.figma.com/proto/FGuAK3K39LI2V1gFHFEqM6/Cheese-Agency?page-id=208%3A4770&node-id=308-7181&viewport=206%2C491%2C0.2&t=DuHgCoFpnyO1c8kT-1&scaling=min-zoom&content-scaling=fixed',
    titleLink: 'Prototype FIGMA cheesy',
    description:
      'Cheesy,Agence spécialisée en création de marques et identités visuelles, alliant créativité et expertise. le site vitrine met en avant ses services, son portfolio, son équipe et les témoignages clients, offrant un aperçu dynamique de son savoir-faire et un moyen de contact direct pour les prospects.',
  },
};

const UiUxInvoice: React.FC = () => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  return isMobile ? <UiUxInvoiceContentMobile themes={contentThemes} /> : <UiUxInvoiceContent contentThemes={contentThemes} />;
};

export default UiUxInvoice;
