import React from 'react';
import { Box, Flex, Grid, GridItem, Image, useBreakpointValue } from '@chakra-ui/react';
import { contentThemesProps, Theme } from '../pages/UiUxInvoice';

interface ThemeListProps {
  themes: { [key in Theme]: contentThemesProps };
}

const UiUxInvoiceContentMobile: React.FC<ThemeListProps> = ({ themes }) => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  return (
    <Grid gap={6} mt="12px">
      {Object.entries(themes).map(([key, themeData]) => (
        <GridItem mx={2} mb={10} key={themeData.title}>
          <Box>
            <Flex justifyContent="center">
              <Box as="h1" textTransform="uppercase" mb={5}>
                {themeData.title}
              </Box>
            </Flex>
            <Flex justifyContent="center" position="relative">
              <Image src={`/medias/ux/${key}/1.jpg`} alt={themeData.title} />
            </Flex>

            <Box mx={isMobile ? '0' : '80px'} textTransform="uppercase" mt={2}>
              <Grid templateColumns="1fr 10fr" gap={2}>
                <GridItem as="p">[Date]</GridItem>
                <GridItem as="p">{themeData.date}</GridItem>
                <GridItem as="p">[Support]</GridItem>
                <GridItem as="p">{themeData.support}</GridItem>
                <GridItem as="p">[Description]</GridItem>
                <GridItem as="p">
                  {themeData.description}{' '}
                  {themeData.link && (
                    <>
                      <GridItem as="p" fontWeight="bold" mt="2">
                        [Liens ]{' '}
                        <a href={themeData.link} target="_blank" rel="noreferrer" style={{ textDecoration: 'underline' }}>
                          {themeData.titleLink}
                        </a>
                      </GridItem>
                    </>
                  )}
                </GridItem>
              </Grid>
            </Box>
          </Box>
        </GridItem>
      ))}
    </Grid>
  );
};

export default UiUxInvoiceContentMobile;
