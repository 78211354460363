import React from 'react';
import { Box, Flex, Image, useBreakpointValue } from '@chakra-ui/react';
import ImageGrid from '../components/ImageGrid';
import ImageGridMobile from '../components/ImageGridMobile';
const ProjetPerso: React.FC = () => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const images = [
    { src: '/medias/perso/1-1.jpg', srcBig: '/medias/perso/big/1-1.jpg', alt: 'STREETs FIGhTERS - Midjourney' },
    { src: '/medias/perso/1-2.jpg', srcBig: '/medias/perso/big/1-2.png', alt: 'CyclopeNG Pickup-services' },
    { src: '/medias/perso/1-3.jpg', srcBig: '/medias/perso/big/1-3.jpg', alt: 'Mac’n’cheese - illustration vectoriel' },
    { src: '/medias/perso/1-4.jpg', srcBig: '/medias/perso/big/1-4.jpg', alt: 'Projet quiz - Jeu mobile' },
    { src: '/medias/perso/1-5.jpg', srcBig: '/medias/perso/big/1-5.png', alt: 'Scene steampunk - Midjourney' },
    { src: '/medias/perso/1-6.jpg', srcBig: '/medias/perso/big/1-6.jpg', alt: 'Persona 1 Midjourney' },
    { src: '/medias/perso/1-7.jpg', srcBig: '/medias/perso/big/1-7.png', alt: 'Persona 2 Midjourney' },
    { src: '/medias/perso/1-8.jpg', srcBig: '/medias/perso/big/1-8.png', alt: 'Persona 3 Midjourney' },
    { src: '/medias/perso/1-9.jpg', srcBig: '/medias/perso/big/1-9.png', alt: 'Persona 4 Midjourney' },
    { src: '/medias/perso/1-10.jpg', srcBig: '/medias/perso/big/1-10.png', alt: 'Persona 5 Midjourne' },
    { src: '/medias/perso/1-11.jpg', srcBig: '/medias/perso/big/1-11.png', alt: 'Persona 6 Midjourney' },
    { src: '/medias/perso/1-12.jpg', srcBig: '/medias/perso/big/1-12.png', alt: 'Goodnite Instagram' },
    { src: '/medias/perso/1-13.jpg', srcBig: '/medias/perso/big/1-13.png', alt: 'Logo ArkamFest' },
  ];

  const images2 = [
    { src: '/medias/perso/2-1.jpg', srcBig: '/medias/perso/big/2-1.jpg', alt: 'Lucie - illustration vectoriel' },
    { src: '/medias/perso/2-2.jpg', srcBig: '/medias/perso/big/2-2.svg', alt: 'FASHion victim - illustration vectoriel' },
    { src: '/medias/perso/2-3.jpg', srcBig: '/medias/perso/big/2-3.jpg', alt: 'MORGEN - logo et branding' },
    { src: '/medias/perso/2-4.jpg', srcBig: '/medias/perso/big/2-4.png', alt: 'Persona 7 Midjourney' },
    { src: '/medias/perso/2-5.jpg', srcBig: '/medias/perso/big/2-5.png', alt: 'Persona 8 Midjourney' },
    { src: '/medias/perso/2-6.jpg', srcBig: '/medias/perso/big/2-6.png', alt: 'Persona 9 Midjourney' },
    { src: '/medias/perso/2-7.jpg', srcBig: '/medias/perso/big/2-7.png', alt: 'Mockup Pub Tropicana 1' },
    { src: '/medias/perso/2-8.jpg', srcBig: '/medias/perso/big/2-8.png', alt: 'Mockup Pub Tropicana 2' },
    { src: '/medias/perso/2-9.jpg', srcBig: '/medias/perso/big/2-9.png', alt: 'Feed Instagram Tropicana' },
    { src: '/medias/perso/2-10.jpg', srcBig: '/medias/perso/big/2-10.png', alt: 'Logo ArkamFest' },
  ];
  return (
    <Flex justifyContent={'center'} direction="column" alignItems={'center'} h="100%">
      {!isMobile && (
        <Box width="95vw">
          <Flex justifyContent="space-between" mb={5} mt={-5}>
            <Image src="/medias/scroll.png" alt="scoll" ml={1} />
            <Image src="/medias/scroll.png" alt="scoll" />
          </Flex>
        </Box>
      )}
      {isMobile ? <ImageGridMobile images={[...images, ...images2]} /> : <ImageGrid images={images} images2={images2} />}
    </Flex>
  );
};

export default ProjetPerso;
