import React, { useEffect, useState } from 'react';
import { Box, Flex, useBreakpointValue } from '@chakra-ui/react';
const Home: React.FC = () => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  useEffect(() => {
    document.body.style.backgroundImage = 'url("/medias/home/home.png")';
    document.body.style.backgroundRepeat = 'no-repeat';
    document.body.style.backgroundSize = 'cover';
    document.body.style.backgroundPosition = 'center';
    return () => {
      document.body.style.backgroundImage = '';
      document.body.style.backgroundRepeat = '';
      document.body.style.backgroundColor = '';
    };
  }, []);

  const [currentDateTime, setCurrentDateTime] = useState<string>('');

  useEffect(() => {
    const now = new Date();
    const day = String(now.getDate()).padStart(2, '0');
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const year = now.getFullYear();
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');

    setCurrentDateTime(`${day}-${month}-${year} ${hours}:${minutes}:${seconds}`);
  }, []);

  useEffect(() => {
    const updateDateTime = () => {
      const now = new Date();
      const day = String(now.getDate()).padStart(2, '0');
      const month = String(now.getMonth() + 1).padStart(2, '0');
      const year = now.getFullYear();
      const hours = String(now.getHours()).padStart(2, '0');
      const minutes = String(now.getMinutes()).padStart(2, '0');
      const seconds = String(now.getSeconds()).padStart(2, '0');

      setCurrentDateTime(`${day}-${month}-${year} ${hours}:${minutes}:${seconds}`);
    };

    // Mettre à jour la date et l'heure toutes les secondes
    const intervalId = setInterval(updateDateTime, 1000);

    // Nettoyer l'intervalle lorsque le composant est démonté
    return () => clearInterval(intervalId);
  }, []);

  return (
    <Flex justifyContent={'bottom'} alignItems={'flex-end'} h="100%">
      <Box>
        <Box position="relative">
          <Flex direction="column">
            <Box className="glitch">
              <div className="line">
                <Box as="h2" fontSize={isMobile ? '30px' : '85px'}>
                  UX.UI CREATIVE
                </Box>
              </div>
              <div className="line">
                <Box as="h2" fontSize={isMobile ? '30px' : '85px'}>
                  UX.UI CREATIVE
                </Box>
              </div>
              <div className="line">
                <Box as="h2" fontSize={isMobile ? '30px' : '85px'}>
                  UX.UI CREATIVE
                </Box>
              </div>
              <div className="line">
                <Box as="h2" fontSize={isMobile ? '30px' : '85px'}>
                  UX.UI CREATIVE
                </Box>
              </div>
              <div className="line">
                <Box as="h2" fontSize={isMobile ? '30px' : '85px'}>
                  UX.UI CREATIVE
                </Box>
              </div>
              <div className="line">
                <Box as="h2" fontSize={isMobile ? '30px' : '85px'}>
                  UX.UI CREATIVE
                </Box>
              </div>
              <div className="line">
                <Box as="h2" fontSize={isMobile ? '30px' : '85px'}>
                  UX.UI CREATIVE
                </Box>
              </div>
              <div className="line">
                <Box as="h2" fontSize={isMobile ? '30px' : '85px'}>
                  UX.UI CREATIVE
                </Box>
              </div>
              <div className="line">
                <Box as="h2" fontSize={isMobile ? '30px' : '85px'}>
                  UX.UI CREATIVE
                </Box>
              </div>
            </Box>
            <Box className="glitch" mt={isMobile ? '-20px' : '-50px'} ml="100px">
              <div className="line">
                <Box as="h2" fontSize={isMobile ? '30px' : '85px'}>
                  DESIGNER
                </Box>
              </div>
              <div className="line">
                <Box as="h2" fontSize={isMobile ? '30px' : '85px'}>
                  DESIGNER
                </Box>
              </div>
              <div className="line">
                <Box as="h2" fontSize={isMobile ? '30px' : '85px'}>
                  DESIGNER
                </Box>
              </div>
              <div className="line">
                <Box as="h2" fontSize={isMobile ? '30px' : '85px'}>
                  DESIGNER
                </Box>
              </div>
              <div className="line">
                <Box as="h2" fontSize={isMobile ? '30px' : '85px'}>
                  DESIGNER
                </Box>
              </div>
              <div className="line">
                <Box as="h2" fontSize={isMobile ? '30px' : '85px'}>
                  DESIGNER
                </Box>
              </div>
              <div className="line">
                <Box as="h2" fontSize={isMobile ? '30px' : '85px'}>
                  DESIGNER
                </Box>
              </div>
              <div className="line">
                <Box as="h2" fontSize={isMobile ? '30px' : '85px'}>
                  DESIGNER
                </Box>
              </div>
              <div className="line">
                <Box as="h2" fontSize={isMobile ? '30px' : '85px'}>
                  DESIGNER
                </Box>
              </div>
            </Box>
          </Flex>
          <Box position="absolute" top={isMobile ? '5px' : '40px'} fontFamily="Fit" fontSize={isMobile ? '60px' : '127px'} zIndex="-1">
            PORTFOLIO 2024
          </Box>
        </Box>

        <Flex direction="column">
          <Box as="p">JuNkP1X3l://19.22.A10.14</Box>
          <Box as="p">#WIlliams</Box>
          <Box as="p">{currentDateTime}</Box>
          <Box as="p">$ UX.UI DESIGNER</Box>
        </Flex>
      </Box>
    </Flex>
  );
};

export default Home;
