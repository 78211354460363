import React, { useState } from 'react';
import { Box, Flex, Grid, Image, useBreakpointValue, Text } from '@chakra-ui/react';
import Lightbox from 'react-awesome-lightbox';
import 'react-awesome-lightbox/build/style.css';
import { customColors } from '../theme';

const ImageGridMobile = ({ images }: { images: any[] }) => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  const openLightbox = (index: number) => {
    setPhotoIndex(index);
    setIsOpen(true);
  };

  return (
    <Box>
      <Grid templateColumns={isMobile ? 'repeat(3, 1fr)' : 'repeat(6, 1fr)'} gap={2} mt="20px">
        {images.map((image, index) => (
          <React.Fragment key={index}>
            <Box onClick={() => openLightbox(index)} cursor="pointer">
              <Image src={image.src} alt={image.alt} />
            </Box>
          </React.Fragment>
        ))}
        <Box gridColumn="span 3">
          <Flex maxW="700px" mt="50px" ml={4}>
            {!isMobile && <Image src="/medias/perso/bot1.png" alt="Bot1" mr={4} />}
            <Box textAlign="left" as="p" fontFamily="Permanent Marker" color={customColors.sinCity} fontSize="14px">
              Passionné par les jeux vidéo de gestion et de rôle, et grand fan de MMORPG, j’adore la créativité, les interactions sociales et la stratégie. Lecteur avide de science-fiction et
              cinéphile des films des années 80-2000, je puise mon inspiration dans l’évolution narrative et visuelle. Je pratique régulièrement le vélo et passe beaucoup de temps sur Pinterest à
              explorer les tendances créatives en design.
            </Box>
            {!isMobile && <Image src="/medias/perso/bot2.png" alt="Bot1" ml={4} />}
          </Flex>
        </Box>
      </Grid>
      {isOpen && (
        <>
          <Lightbox image={images[photoIndex].srcBig} images={images.map(img => ({ url: img.srcBig, title: img.alt }))} startIndex={photoIndex} onClose={() => setIsOpen(false)} />
        </>
      )}
    </Box>
  );
};

export default ImageGridMobile;
