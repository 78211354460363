import React from 'react';
import { Box, Divider, Grid, Image, ListItem, UnorderedList, useBreakpointValue } from '@chakra-ui/react';
import { customColors } from '../theme';
const Humain: React.FC = () => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  return (
    <Box mx={isMobile ? 0 : 10}>
      {!isMobile && <Image position="absolute" bottom="0" right="0" src={`/medias/humain/bot.png`} alt={'bot'} boxShadow="0 0 10px rgba(0, 0, 0, 0.5)" />}
      <Grid templateColumns={isMobile ? 'auto' : '1.5fr 5fr  '} mt={isMobile ? '5px' : '-20px'} w="100%" ml={isMobile ? 0 : 10}>
        <Box h="100%" p={4}>
          <Box>
            <Image src="/medias/humain/me.jpg" alt="me" />
            <Box as="h1" fontSize="21px" mt={4}>
              Williams CHATONY
            </Box>
            <Box as="h1" fontSize="21px" mt="-7px">
              UI CREATIVE designer
            </Box>

            <Divider my={3} />
            <Box as="p">williamschatony@hotmail.com</Box>
            <Box as="p">France</Box>
          </Box>
        </Box>
        <Grid templateColumns={isMobile ? 'auto' : '2fr 3fr'} sx={{ overflowY: isMobile ? 'none' : 'auto', maxHeight: isMobile ? 'auto' : '70vh' }} w="100%">
          <Box h="100%" p={4}>
            <Box>
              <Box border="1px" p={2} borderColor={customColors.cilicium}>
                SKILLS
              </Box>
              <Box border="1px" p={2} borderColor={customColors.cilicium} textTransform="uppercase">
                <Box>
                  <Box as="p" fontWeight="700">
                    Conception Visuelle
                  </Box>
                  <Box as="p">Création de maquettes, wireframes et prototypes.</Box>
                  <Box as="p" mt="2">
                    Développement d’identités visuelles et guidelines graphiques.
                  </Box>
                </Box>
                <Box>
                  <Box as="p" fontWeight="700" mt={3}>
                    Logiciels de Design
                  </Box>
                  <Box as="p">Maîtrise de Figma, Photoshop, Illustrator et InDesign.</Box>
                  <Box as="p" mt="2">
                    Utilisation de After Effects pour des animations légères.
                  </Box>
                </Box>
                <Box>
                  <Box as="p" fontWeight="700" mt={3}>
                    Stratégie Réseaux
                  </Box>
                  <Box as="p">Gestion et optimisation de la présence digitale sur Instagram, TikTok et LinkedIn.</Box>
                  <Box as="p" mt="2">
                    Planification et création de contenu engageant.
                  </Box>
                </Box>
                <Box>
                  <Box as="p" fontWeight="700" mt={3}>
                    SEO & Rédaction
                  </Box>
                  <Box as="p">Rédaction de contenus optimisés et stratégie de référencement naturel.</Box>
                  <Box as="p" mt="2">
                    Analyse des performances et ajustements basés sur les KPIs.
                  </Box>
                </Box>
                <Box>
                  <Box as="p" fontWeight="700" mt={3}>
                    Gestion de Projets
                  </Box>
                  <Box as="p">Planification, suivi et coordination en mode agile.</Box>
                  <Box as="p" mt="2">
                    Collaboration avec des équipes créatives et techniques.
                  </Box>
                </Box>
                <Box>
                  <Box as="p" fontWeight="700" mt={3}>
                    Créativité et Innovation
                  </Box>
                  <Box as="p">Proposition de solutions créatives et innovantes pour maximiser l’engagement.</Box>
                  <Box as="p" mt="2">
                    Veille sur les tendances UX/UI et branding.
                  </Box>
                </Box>
                <Box>
                  <Box as="p" fontWeight="700" mt={3}>
                    Connaissance IT
                  </Box>
                  <Box as="p">Expérience avec Midjourney, ChatGPT, Firefly et Runway.</Box>
                  <Box as="p" mt="2">
                    Capacité à apprendre rapidement et à m'adapter aux nouvelles technologies.
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box mt={4}>
              <Box border="1px" p={2} borderColor={customColors.cilicium} textTransform="uppercase">
                formations
              </Box>
              <Box border="1px" p={2} borderColor={customColors.cilicium} textTransform="uppercase">
                <Box>
                  <Box as="p" fontWeight="700" mt={2}>
                    BTS Designer print & web
                  </Box>
                  <Box as="p">NextFormation, Vincennes</Box>
                  <Box as="p">2022</Box>
                </Box>
                <Box>
                  <Box as="p" fontWeight="700" mt={2}>
                    Prépa communication visuelle
                  </Box>
                  <Box as="p">Créapole-ESDI, Paris I</Box>
                  <Box as="p">2003-2004</Box>
                </Box>
                <Box>
                  <Box as="p" fontWeight="700" mt={2}>
                    Prépa stylisme Modélisme
                  </Box>
                  <Box as="p">Atelier Letelier, Paris XV</Box>
                  <Box as="p">2002-2003</Box>
                </Box>
                <Box>
                  <Box as="p" fontWeight="700" mt={2}>
                    CAP/BAC PRO Photo
                  </Box>
                  <Box as="p">Lycée Quinault, Paris XV</Box>
                  <Box as="p">1999-2001</Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box h="100%" p={4}>
            <Box textTransform="uppercase">
              <Box as="p" fontSize="13px" fontWeight="700" mt={4}>
                Expériences Professionnelles
              </Box>
              <Box>
                <Box as="p" fontWeight="700" mt={4}>
                  Branding & Influence
                </Box>
                <Box as="p">Goodnite.fr</Box>
                <Box as="p">2024 - 2024</Box>
                <UnorderedList my={2}>
                  <ListItem>Création de contenus engageants (vidéos, carrousels) pour renforcer la connexion avec l’audience.</ListItem>
                  <ListItem>Développement d’une stratégie d’influence authentique et humaine, en sélectionnant les bons profils.</ListItem>
                  <ListItem>Encadrement et animation d’une team e-sport pour favoriser la cohésion et l’image de marque.</ListItem>
                </UnorderedList>
              </Box>
              <Box>
                <Box as="p" fontWeight="700" mt={4}>
                  UX.UI Designer
                </Box>
                <Box as="p">Pickup-Services</Box>
                <Box as="p">2022-2024</Box>
                <UnorderedList my={2}>
                  <ListItem>Création de maquettes et prototypes pour l'application mobile des points relais en Europe.</ListItem>
                  <ListItem>Conception de maquettes pour l'application web interne de facturation.</ListItem>
                  <ListItem>Animation d'ateliers de brainstorming créatif avec les équipes de développement.</ListItem>
                </UnorderedList>
              </Box>

              <Box>
                <Box as="p" fontWeight="700" mt={4}>
                  Stagiaire marketing
                </Box>
                <Box as="p">Pickup-Services</Box>
                <Box as="p">2022-2022</Box>
                <UnorderedList my={2}>
                  <ListItem>Réalisation d’illustrations.</ListItem>
                  <ListItem>Retouche numérique de photos.</ListItem>
                  <ListItem>Création de bannières web.</ListItem>
                  <ListItem>Correction, création et mise en page de divers documents avec InDesign et Illustrator.</ListItem>
                </UnorderedList>
              </Box>

              <Box>
                <Box as="p" fontWeight="700" mt={4}>
                  Ingénieur Exploitation
                </Box>
                <Box as="p">Pickup-Services</Box>
                <Box as="p">2018-2022</Box>
                <UnorderedList my={2}>
                  <ListItem>Gestion de l'infrastructure IT et des opérations quotidiennes.</ListItem>
                  <ListItem>Supervision et assurance de la continuité des services.</ListItem>
                  <ListItem>Mise en place de procédures.</ListItem>
                  <ListItem>Mise en production des applications.</ListItem>
                </UnorderedList>
              </Box>

              <Box>
                <Box as="p" fontWeight="700" mt={4}>
                  Technicien Systèmes & Réseaux
                </Box>
                <Box as="p">Pickup-Services</Box>
                <Box as="p">2013-2018</Box>
                <UnorderedList my={2}>
                  <ListItem>Maintenance et gestion des réseaux informatiques.</ListItem>
                  <ListItem>Administration de SharePoint et gestion de l’Active Directory.</ListItem>
                  <ListItem>Installation et configuration des équipements systèmes et réseaux.</ListItem>
                  <ListItem>Support technique aux utilisateurs et résolution de problèmes.</ListItem>
                </UnorderedList>
              </Box>

              <Box>
                <Box as="p" fontWeight="700" mt={4}>
                  Technicien Systèmes & Réseaux
                </Box>
                <Box as="p">Matis</Box>
                <Box as="p">2010-2013</Box>
                <UnorderedList my={2}>
                  <ListItem>Supervision et assurance de la continuité des services.</ListItem>
                  <ListItem>Mise en place de procédures.</ListItem>
                </UnorderedList>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Humain;
