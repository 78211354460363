import React from 'react';
import { Box, Flex, Grid, GridItem, Image, useBreakpointValue } from '@chakra-ui/react';
import { contentThemesProps, Theme } from '../pages/UiUxInvoice';

const UiUxInvoiceContent = ({
  contentThemes,
}: {
  contentThemes: {
    eg: contentThemesProps;
    ams: contentThemesProps;
    invoice: contentThemesProps;
    ams2: contentThemesProps;
    nature: contentThemesProps;
    goodnite: contentThemesProps;
    cheezy: contentThemesProps;
  };
}) => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const [selectedTheme, setSelectedTheme] = React.useState<Theme>('eg');
  const [selectedMedia, setSelectedMedia] = React.useState<number>(1);

  const onSelectedTheme = (theme: Theme) => {
    setSelectedTheme(theme);
    setSelectedMedia(1);
  };

  return (
    <Grid templateColumns={isMobile ? 'auto' : '1fr 3.5fr'} gap={6} mt="60px">
      <GridItem>
        <Flex direction="column" mt={-10} justifyContent="center" alignItems="center">
          {[
            { theme: 'ams2', src: '/medias/ux/1.png', alt: 'ams 2', maxW: '246px' },
            { theme: 'invoice', src: '/medias/ux/2.png', alt: 'invoice', maxW: '258px' },
            { theme: 'ams', src: '/medias/ux/3.png', alt: 'ams 1', maxW: '258px' },
            { theme: 'eg', src: '/medias/ux/4.png', alt: 'EG', maxW: '258px' },
            { theme: 'nature', src: '/medias/ux/5.png', alt: 'Nature Morte', maxW: '246px' },
            { theme: 'goodnite', src: '/medias/ux/6.png', alt: 'Goodnite', maxW: '246px' },
            { theme: 'cheezy', src: '/medias/ux/7.png', alt: 'Cheezy', maxW: '246px' },
          ].map((item, index) => (
            <Box
              key={item.theme}
              mt={index === 0 ? 4 : '-83px'}
              ml={2}
              onClick={() => onSelectedTheme(item.theme as Theme)}
              _hover={{ transform: 'translateY(-10px)', zIndex: 0 }}
              transition="transform 0.3s"
              position="relative"
              zIndex={0}
              cursor="pointer"
            >
              <Image src={item.src} alt={item.alt} maxW={item.maxW} />
            </Box>
          ))}
        </Flex>
      </GridItem>
      <GridItem mx={20}>
        <Box mt={'-120px'}>
          <Flex justifyContent="center">
            <Box as="h1" textTransform="uppercase" mb={5}>
              {contentThemes[selectedTheme].title}
            </Box>
          </Flex>
          <Flex justifyContent="center" position="relative">
            <Image src={`/medias/ux/${selectedTheme}/${selectedMedia}.jpg`} alt={selectedTheme} />
            {selectedTheme !== 'goodnite' && (
              <Box position="absolute" bottom="0" right="0" mb={2}>
                <Flex>
                  <Box onClick={() => setSelectedMedia(1)} cursor="pointer" mr={2}>
                    <Image src={`/medias/ux/${selectedTheme}/min/1.jpg`} alt={selectedTheme} boxShadow="0 0 10px rgba(0, 0, 0, 0.5)" />
                  </Box>
                  <Box onClick={() => setSelectedMedia(2)} cursor="pointer" mr={2}>
                    <Image src={`/medias/ux/${selectedTheme}/min/2.jpg`} alt={selectedTheme} boxShadow="0 0 10px rgba(0, 0, 0, 0.5)" />
                  </Box>
                </Flex>
              </Box>
            )}
          </Flex>

          <Box mx={isMobile ? '0' : '80px'} textTransform="uppercase" mt={2}>
            <Grid templateColumns="1fr 10fr" gap={2}>
              <GridItem as="p">[Date]</GridItem>
              <GridItem as="p">{contentThemes[selectedTheme].date}</GridItem>
              <GridItem as="p">[Support]</GridItem>
              <GridItem as="p">{contentThemes[selectedTheme].support}</GridItem>
              <GridItem as="p">[Description]</GridItem>
              <GridItem as="p">
                {contentThemes[selectedTheme].description}{' '}
                {contentThemes[selectedTheme].link && (
                  <>
                    <GridItem as="p" fontWeight="bold" mt="2">
                      [Liens ]{' '}
                      <a href={contentThemes[selectedTheme].link} target="_blank" rel="noreferrer" style={{ textDecoration: 'underline' }}>
                        {contentThemes[selectedTheme].titleLink}
                      </a>
                    </GridItem>
                  </>
                )}
              </GridItem>
            </Grid>
          </Box>
        </Box>
      </GridItem>
    </Grid>
  );
};

export default UiUxInvoiceContent;
