import React, { useState, useRef, useEffect } from 'react';
import { Box, Flex, Image } from '@chakra-ui/react';
import Lightbox from 'react-awesome-lightbox';
import 'react-awesome-lightbox/build/style.css';

const ImageGrid = ({ images, images2 }: { images: any[]; images2: any[] }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [currentGallery, setCurrentGallery] = useState<'images' | 'images2'>('images'); // Savoir si c'est la première ou deuxième ligne
  const scrollRef = useRef<HTMLDivElement>(null);

  // Fonction pour ouvrir la lightbox avec la bonne galerie
  const openLightbox = (index: number, gallery: 'images' | 'images2') => {
    setPhotoIndex(index);
    setCurrentGallery(gallery);
    setIsOpen(true);
  };

  // Gestion du scroll horizontal avec la molette
  useEffect(() => {
    const handleWheel = (event: WheelEvent) => {
      if (scrollRef.current) {
        event.preventDefault();
        scrollRef.current.scrollLeft += event.deltaY;
      }
    };

    const element = scrollRef.current;
    if (element) {
      element.addEventListener('wheel', handleWheel, { passive: false });
    }

    return () => {
      if (element) {
        element.removeEventListener('wheel', handleWheel);
      }
    };
  }, []);

  // Effet de scroll infini
  useEffect(() => {
    const element = scrollRef.current;
    if (!element) return;

    const duplicateScroll = () => {
      if (element.scrollLeft >= element.scrollWidth / 2) {
        element.scrollLeft = 0;
      }
    };

    element.addEventListener('scroll', duplicateScroll);
    return () => element.removeEventListener('scroll', duplicateScroll);
  }, []);

  // Sélectionne les bonnes images pour la Lightbox
  const lightboxImages = currentGallery === 'images' ? images.map(img => ({ url: img.srcBig, title: img.alt })) : images2.map(img => ({ url: img.srcBig, title: img.alt }));

  return (
    <Box overflow="hidden" width="95vw">
      {/* Conteneur scrollable */}
      <Box
        ref={scrollRef}
        overflowX="auto"
        whiteSpace="nowrap"
        width="100vw"
        sx={{
          '::-webkit-scrollbar': { display: 'none' },
          scrollbarWidth: 'none',
        }}
      >
        <Flex direction="column" gap={2}>
          {/* Première ligne d'images */}
          <Flex>
            {[...images, ...images].map((image, index) => (
              <Box key={`row1-${index}`} flex="0 0 auto" cursor="pointer" onClick={() => openLightbox(index % images.length, 'images')} mx={1}>
                <Image src={image.src} alt={image.alt} height="200px" objectFit="cover" />
              </Box>
            ))}
          </Flex>

          {/* Deuxième ligne d'images avec le contenu spécial */}
          <Flex>
            {[...images2, ...images2].map((image, index) => (
              <React.Fragment key={`row2-${index}`}>
                <Box flex="0 0 auto" cursor="pointer" onClick={() => openLightbox(index % images2.length, 'images2')} mx={1}>
                  <Image src={image.src} alt={image.alt} height="200px" objectFit="cover" />
                </Box>
                {index === 2 && (
                  <Flex style={{ minWidth: '605px' }} mt="50px" ml={4}>
                    <Image src="/medias/perso/bot1.png" maxH="128px" alt="Bot1" mr={4} />
                    <Box textAlign="left" as="p" fontFamily="Permanent Marker" fontSize="12px">
                      Passionné par les jeux vidéo de gestion et de rôle, et <br />
                      grand fan de MMORPG, j’adore la créativité, les <br />
                      interactions sociales et la stratégie. Lecteur avide de
                      <br />
                      science-fiction et cinéphile des films des années <br />
                      80-2000, je puise mon inspiration dans l’évolution
                      <br />
                      narrative et visuelle. Je pratique régulièrement le <br />
                      vélo et passe beaucoup de temps sur Pinterest à<br />
                      explorer les tendances créatives en design.
                    </Box>
                    <Image src="/medias/perso/bot2.png" alt="Bot2" ml={4} />
                  </Flex>
                )}
              </React.Fragment>
            ))}
          </Flex>
        </Flex>
      </Box>
      {/* Lightbox avec la bonne galerie */}
      {isOpen && <Lightbox image={lightboxImages[photoIndex].url} images={lightboxImages} startIndex={photoIndex} onClose={() => setIsOpen(false)} />}
    </Box>
  );
};

export default ImageGrid;
